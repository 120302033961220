<template>
  <div class="ev2-box console-tasks-queue l550 hscroll">
    <div class="header">
      <div class="hcol1">
        <div class="title">Taxas de domínios pendentes: <small>({{table.serverPagination.rowsNumber}})</small></div>
        <div class="subtitle">Leiloeiros com taxas pendentes/atrasadas</div>
      </div>
      <div class="hcol2">
        <i class="fal fa-refresh cursor-pointer" @click="load" />
      </div>
    </div>
    <div class="body m-t">
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="ev2-dash-table with-bottom size-sm time-lista"
          :rows-per-page-options="[20, 50, 100]"
      >
        <u-tr slot="header" slot-scope="props">
          <u-th v-for="col in props.cols" :key="col.name" :style="parseStyle(col)" :props="props">
            <template>
              {{ col.label }}
            </template>
            <div v-if="col.name === 'options'" class="text-center">
            </div>
          </u-th>
        </u-tr>

        <u-tr @click.native="abrir(props.row.id)" class="colaborador cursor-pointer" slot="body" slot-scope="props" :props="props" :class="bindTrClass(props.row)">
          <u-td style="width: 40px !important; max-width: 40px" key="id" :props="props">
            {{props.row.id}}
          </u-td>
          <u-td class="text-left" key="ticket" :props="props">
            <div v-if="props.row.extra">
              {{props.row.extra[0]}}
            </div>
            <span v-else></span>
          </u-td>
          <u-td class="td-limit l-200" key="dominio" :props="props">
            <div v-if="props.row.extra">
              {{props.row.extra[1]}}
            </div>
            <span v-else></span>
          </u-td>
          <u-td class="td-limit l-200" key="cliente" :props="props">
            <div v-if="props.row.pessoa">
              {{props.row.pessoa.name}}
            </div>
            <span v-else></span>
          </u-td>

          <u-td key="dataVencimento" :props="props">
            <div class="cursor-pointer">
              {{props.row.dataVencimento|formatDate('dd/MM/yyyy')}}
            </div>
          </u-td>
          <u-td class="text-center" style="text-align: right!important;" key="valor" :props="props">
            R$ {{props.row.valor|moeda}}
          </u-td>
        </u-tr>
      </u-table>
    </div>
<!--    <div class="footer">
      <div class="text-center">
        <u-btn class="f-btn" no-caps label="Novo Registro" />
      </div>
    </div>-->
  </div>
</template>

<script>
import ErpSelect from "@/plugins/uloc-erp/components/form/select"
import tableColumnsCache from "@/utils/tableColumnsCache"
import {UTable, UTr, UTh, UTd, date, UPopover} from "uloc-vue"
import {datePtToEn} from "@/utils/date"
import taskListWindow from '@/components/fenaju/components/suporte/dashboard/window/taskListWindow'
import {differenceInHours, parseISO} from "date-fns"
import {listConta as list} from "@/domain/financeiro/services"
import {donwloadFile} from "@/utils/downloadFile"
import windowConta from "components/financeiro/windows/conta";

let filters = {
  id: null,
}

const listName = 'table.console.financeiro.dash'
const listStorage = tableColumnsCache(listName, [
  {label: 'ID', name: 'id', active: false, sortable: true, ordering: 1},
  {label: 'Ticket', name: 'ticket', active: true, sortable: true, ordering: 2},
  {label: 'Domínio', name: 'dominio', active: true, sortable: true, ordering: 3},
  {label: 'Leiloeiro', name: 'cliente', active: true, sortable: true, ordering: 4},
  {label: 'Vencimento', name: 'dataVencimento', active: true, sortable: true, ordering: 5},
  {label: 'Valor', name: 'valor', active: true, sortable: true, ordering: 6},
], 1)
export default {
  name: "ConsolePagamentosPendentes",
  components: {
    UTable, UTr, UTh, UTd
  },
  data () {
    const filtros = JSON.parse(JSON.stringify(filters))
    filtros.pago = false
    filtros.cancelado = false
    return {
      busca: null,
      listStorage: listStorage,
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 0, // specifying this determines pagination is server-side
          rowsPerPage: 100
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        })/*.concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ])*/,
        filter: '',
        filters: filtros,
        selected: [],
        loading: false
      }
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    }
  },
  methods: {
    load () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    request({pagination, filter}, exportar = null) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || this.table.filters.data2.length < 10) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.table.filters.tipoData && extraFilters.push(`&typeDate=${this.table.filters.tipoData}`)

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.tipo && extraFilters.push(`&tipo=${this.table.filters.tipo}`)
      this.table.filters.search && extraFilters.push(`&search=${this.table.filters.search}`)

      if (Array.isArray(this.table.filters.status) && this.table.filters.status.length > 0) {
        extraFilters.push(`&status=${this.table.filters.status.join(',')}`)
      }

      if (Array.isArray(this.table.filters.conta)) {
        extraFilters.push(`&conta=${this.table.filters.conta.join(',')}`)
      }

      this.relacaoEntidade && extraFilters.push(`&entity=${this.relacaoEntidade}`)
      this.relacaoEntidadeId && extraFilters.push(`&entityId=${this.relacaoEntidadeId}`)

      this.table.filters.onlyOwnerTasks && extraFilters.push(`&onlyOwnerTasks=${this.table.filters.onlyOwnerTasks}`)
      this.table.filters.participants && extraFilters.push(`&participants=${this.table.filters.participants}`)
      this.table.filters.participants && extraFilters.push(`&participantFilter=${this.table.filters.participantFilter}`)

      this.table.filters.dueFilter && extraFilters.push(`&dueFilter=${this.table.filters.dueFilter}`)
      this.table.filters.centroCusto && extraFilters.push(`&centroCusto=${this.table.filters.centroCusto.id}`)
      this.table.filters.relationEntityId && extraFilters.push(`&relationEntityId=${this.table.filters.relationEntityId}&relationEntity=${this.table.filters.relationEntity}`) // @TODO: Isso é usado?

      this.table.filters.pessoa && extraFilters.push(`&pessoa=${this.table.filters.pessoa.id || this.table.filters.pessoa}`)
      this.table.filters.categoria && extraFilters.push(`&categoria=${this.table.filters.categoria}`)
      this.table.filters.processo && extraFilters.push(`&processo=${this.table.filters.processo.id}`)

      if (this.parseProps) {
        console.log(this.parseProps)
        if (this.parseProps.module === 'processos') {
          this.parseProps.processo && extraFilters.push(`&processo=${this.parseProps.processo.id}`)
        } else {
          this.parseProps && this.parseProps.relationEntity && extraFilters.push(`&relationEntity=${this.parseProps.relationEntity}`)
          this.parseProps && this.parseProps.relationEntityId && extraFilters.push(`&relationEntityId=${this.parseProps.relationEntityId}`)
          this.parseProps && this.parseProps.relationEntityId && extraFilters.push(`&relationEntityId=${this.parseProps.relationEntityId}`)
        }
      }

      if (this.table.filters.dedutivel === true || this.table.filters.dedutivel === false) {
        extraFilters.push(`&dedutivel=${this.table.filters.dedutivel ? 1 : 0}`)
      }

      if (this.table.filters.pago === true || this.table.filters.pago === false) {
        extraFilters.push(`&pago=${this.table.filters.pago ? 1 : 0}`)
      }


      if (exportar) {
        extraFilters.push(`&export=${exportar}`)
      }

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'dataVencimento'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : false}&${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros, exportar)
          .then((response) => {
            if (exportar) {
              donwloadFile(response)
              this.table.loading = false
              return
            }
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = response.data.total
            this.table.serverData = response.data.result
            this.stats = response.data.stats
            if (response.data.counts) {
              this.counts = response.data.counts
            }

            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
            this.table.loading = false
          })
    },
    showTasklist (list) {
      this.taskListWindow(list)
    },
    bindTrClass (task) {
      const css = []
      task.deadline && this.isOpen(task) && this.atraso(task.deadline) > 0 && css.push('is-atrasado')
      return css
    },
    parseStyle (col) {
      let css = ''
      if (['id', 'queue'].includes(col.name)) {
        css = css + 'width: 40px; max-width: 40px;'
      }
      if (['valor'].includes(col.name)) {
        css = css + 'text-align: right !important; display: flex; flex-direction: row-reverse'
      }
      return css
    },
    windowConta: windowConta,
    abrir(id) {
      // this.$router.push({name: 'sl.tarefas.show', params: {id: id}})
      this.windowConta(id, this.listType)
    },
  }
}
</script>
